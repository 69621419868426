// Generated by Framer (089dccc)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NpswGzLqd"];

const variantClassNames = {NpswGzLqd: "framer-v-1oy6e1s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "NpswGzLqd", title: QwW6pMx8m = "Explore Pages", background: VXiDBj0CA = "var(--token-2e606580-c5cb-45c2-a66e-07cb8dbe5a38, rgb(116, 33, 252)) /* {\"name\":\"Purple\"} */", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NpswGzLqd", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fjBR4", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.button {...restProps} className={cx("framer-1oy6e1s", className)} data-border data-framer-name={"Variant 1"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"NpswGzLqd"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.08)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: VXiDBj0CA, borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, filter: "brightness(1)", WebkitFilter: "brightness(1)", ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 style={{"--font-selector": "R0Y7TWFucm9wZS04MDA=", "--framer-font-family": "\"Manrope\", serif", "--framer-font-size": "10px", "--framer-font-weight": "800", "--framer-letter-spacing": "0.5px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-a0htzi)", "--framer-text-transform": "uppercase"}}>Explore Pages</motion.h3></React.Fragment>} className={"framer-b7myc2"} fonts={["GF;Manrope-800"]} layoutDependency={layoutDependency} layoutId={"DKYdfGB2m"} style={{"--extracted-a0htzi": "var(--token-8c47652b-dea5-4767-a9f2-5d952dcce49a, rgb(255, 255, 255)) ", "--framer-link-current-text-color": "hsl(0, 0%, 0%)", "--framer-link-hover-text-color": "#666", "--framer-paragraph-spacing": "0px"}} text={QwW6pMx8m} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.button>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fjBR4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fjBR4 * { box-sizing: border-box; }", ".framer-fjBR4 .framer-1oy6e1s { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: visible; padding: 8px 15px 8px 15px; position: relative; width: min-content; }", ".framer-fjBR4 .framer-b7myc2 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fjBR4 framer-1oy6e1s > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-fjBR4 framer-1oy6e1s > :first-child { margin-left: 0px; } .framer-fjBR4 framer-1oy6e1s > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 116
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"QwW6pMx8m":"title","VXiDBj0CA":"background"}
 */
const Framerqo45gQxGu: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerqo45gQxGu;

Framerqo45gQxGu.displayName = "Badge";

Framerqo45gQxGu.defaultProps = {height: 30, width: 116};

addPropertyControls(Framerqo45gQxGu, {QwW6pMx8m: {defaultValue: "Explore Pages", displayTextArea: false, title: "Title", type: ControlType.String}, VXiDBj0CA: {defaultValue: "var(--token-2e606580-c5cb-45c2-a66e-07cb8dbe5a38, rgb(116, 33, 252)) /* {\"name\":\"Purple\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(Framerqo45gQxGu, [{family: "Manrope", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/qo45gQxGu:default", url: "https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf", weight: "800"}])